import { getFieldRule } from '@/utils/listingFormUtils'; // Adjust the path based on your project structure

const requireContext = require.context('./form', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  listingName: null,
  listingDesc: null,
  usePinLocation: false,
  lat: null,
  lng: null,
  address: null,
  price: null,
  propertyTypes: [],
  selectedPropertyType: null,
  certificates: [],
  selectedCertificate: null,
  furnishedStatuses: [],
  selectedFurnishedStatus: null,
  loadingMap: false,
  categoryName: null, //Nama Perumahan / Cluster,
  unitNumber: null,
  blockNumber: null,
  facilities: [],
  bedroom: null,
  bathroom: null,
  buildingSize: null,
  landSize: null,
  totalFloor: null,
  floor: null,
  fieldRules: [],
  agentUuid: null,
  electricalPower: null,
  hoek: null,
  youtubeUrl: null,
  listingNumber: null,
});

export const getters = {
  bedroomRule(state, _, rootState) {
    return getFieldRule(rootState.listing.form.selectedPropertyType, state.fieldRules, 'BEDROOM');
  },
  bathroomRule(state, _, rootState) {
    return getFieldRule(rootState.listing.form.selectedPropertyType, state.fieldRules, 'BATHROOM');
  },

  blockNumberRule(state, _, rootState) {
    return getFieldRule(
      rootState.listing.form.selectedPropertyType,
      state.fieldRules,
      'UNIT_BLOCK',
    );
  },
  landSizeRule(state, _, rootState) {
    return getFieldRule(rootState.listing.form.selectedPropertyType, state.fieldRules, 'LAND_SIZE');
  },
  totalFloorRule(state, _, rootState) {
    return getFieldRule(
      rootState.listing.form.selectedPropertyType,
      state.fieldRules,
      'TOTAL_FLOOR',
    );
  },
  floorRule(state, _, rootState) {
    return getFieldRule(rootState.listing.form.selectedPropertyType, state.fieldRules, 'FLOOR');
  },
  buildingSizeRule(state, _, rootState) {
    return getFieldRule(
      rootState.listing.form.selectedPropertyType,
      state.fieldRules,
      'BUILDING_SIZE',
    );
  },
  electricalPowerRule(state, _, rootState) {
    return getFieldRule(
      rootState.listing.form.selectedPropertyType,
      state.fieldRules,
      'ELECTRICAL_POWER',
    );
  },
  hoekRule(state, _, rootState) {
    return getFieldRule(rootState.listing.form.selectedPropertyType, state.fieldRules, 'HOEK');
  },
  furnishingStatusRule(state, _, rootState) {
    return getFieldRule(
      rootState.listing.form.selectedPropertyType,
      state.fieldRules,
      'FURNISHING_STATUS',
    );
  },
  facilitiesWithUnit(state) {
    return state.facilities.filter(function (facility) {
      return facility.uom_name !== null;
    });
  },
  facilitiesWithoutUnit(state) {
    return state.facilities.filter(function (facility) {
      return facility.uom_name === null;
    });
  },
  formData(state, getters) {
    const mediaFormData = getters['media/formData'];
    const regionalFormData = getters['regional/formData'];

    const facilities = JSON.parse(JSON.stringify(state.facilities));
    const selectedFacilities = facilities
      .filter((facility) => facility.selected)
      .map((selected) => {
        return {
          id: selected.id,
          amount: selected.amount === undefined ? null : selected.amount,
        };
      });

    let selectedHoek = null;
    if (state.hoek !== null) {
      selectedHoek = state.hoek === 1;
    }

    let currentFormData = {
      uuid: state.uuid,
      lat: state.lat,
      lng: state.lng,
      address: state.address,
      village_id: state.regional.village,
      property_type_id: state.selectedPropertyType ? state.selectedPropertyType.id : null,
      certificate_id: state.selectedCertificate ? state.selectedCertificate.id : null,
      furnishing_status_id: state.selectedFurnishedStatus ? state.selectedFurnishedStatus.id : null,
      category_name: state.categoryName,
      block_number: state.blockNumber,
      unit_number: state.unitNumber,
      title_suffix: state.listingName,
      description: state.listingDesc,
      price: state.price,
      bedroom: state.bedroom,
      bathroom: state.bathroom,
      building_size: state.buildingSize,
      land_size: state.landSize,
      total_floor: state.totalFloor,
      floor: state.floor,
      selected_facilities: selectedFacilities,
      agent_uuid: state.agentUuid,
      electrical_power: state.electricalPower,
      hoek: selectedHoek,
      youtube_url: state.youtubeUrl,
    };
    return Object.assign({}, currentFormData, mediaFormData, regionalFormData);
  },
};

export const mutations = {
  SET_LISTING_NAME(state, uuid) {
    state.listingName = uuid;
  },
  SET_LISTING_DESC(state, uuid) {
    state.listingDesc = uuid;
  },
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LOADING_MAP(state, payload) {
    state.loadingMap = payload;
  },
  SET_USE_PIN_LOCATION(state, payload) {
    state.usePinLocation = payload;
  },
  SET_LAT(state, payload) {
    state.lat = payload;
  },
  SET_LNG(state, payload) {
    state.lng = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_PRICE(state, payload) {
    state.price = payload;
  },
  SET_PROPERTY_TYPES(state, payload) {
    state.propertyTypes = payload;
  },
  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
  SET_CERTIFICATES(state, payload) {
    state.certificates = payload;
  },
  SET_SELECTED_CERTIFICATE(state, payload) {
    state.selectedCertificate = payload;
  },
  SET_FURNISHED_STATUSES(state, payload) {
    state.furnishedStatuses = payload;
  },
  SET_SELECTED_FURNISHED_STATUS(state, payload) {
    state.selectedFurnishedStatus = payload;
  },
  SET_CATEGORY_NAME(state, payload) {
    state.categoryName = payload;
  },
  SET_UNIT_NUMBER(state, payload) {
    state.unitNumber = payload;
  },
  SET_BLOCK_NUMBER(state, payload) {
    state.blockNumber = payload;
  },

  SET_FACILITIES(state, payload) {
    state.facilities = payload.map(function (item) {
      item.selected = false;
      if (item.uom_name !== null) {
        item.amount = null;
      }
      return item;
    });
  },
  TOGGLE_SELECTED_FACILITY(state, facilityId) {
    const facility = state.facilities.find(function (facility) {
      return facility.id == facilityId;
    });
    facility.selected = !facility.selected;

    if (!facility.selected) {
      if (facility.uom_name !== null) {
        facility.amount = null;
      }
    }
  },
  SET_FACILITY_AMOUNT(state, { facilityId, amount }) {
    let facility = state.facilities.find(function (fac) {
      return fac.id == facilityId;
    });
    facility.amount = amount;
  },

  SET_BEDROOM(state, payload) {
    state.bedroom = payload;
  },
  SET_BATHROOM(state, payload) {
    state.bathroom = payload;
  },
  SET_BUILDING_SIZE(state, payload) {
    state.buildingSize = payload;
  },
  SET_LAND_SIZE(state, payload) {
    state.landSize = payload;
  },
  SET_TOTAL_FLOOR(state, payload) {
    state.totalFloor = payload;
  },
  SET_FLOOR(state, payload) {
    state.floor = payload;
  },
  SET_FIELD_RULES(state, payload) {
    state.fieldRules = payload;
  },
  SET_AGENT_UUID(state, payload) {
    state.agentUuid = payload;
  },
  SET_ELECTRICAL_POWER(state, payload) {
    state.electricalPower = payload;
  },
  SET_HOEK(state, payload) {
    state.hoek = payload;
  },
  SET_YOUTUBE_URL(state, payload) {
    state.youtubeUrl = payload;
  },
  SET_LISTING_NUMBER(state, payload) {
    state.listingNumber = payload;
  },
};

export const actions = {
  postData: async function ({ state, commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      formData.action = state.formType === 'ADD' ? 'activate' : 'save';
      const url = '/api/data_entry_listings/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  applyBaseData({ commit }, baseData) {
    commit('SET_PROPERTY_TYPES', baseData.property_types);
    commit('SET_CERTIFICATES', baseData.certificates);
    commit('SET_FURNISHED_STATUSES', baseData.furnishing_statuses);
    commit('SET_FACILITIES', baseData.facilities);
    commit('SET_FIELD_RULES', baseData.field_rules);
    commit('regional/SET_PROVINCES', baseData.provinces);
    commit('SET_LISTING_NUMBER', null);
  },

  async applyEditData({ commit, state, dispatch }, data) {
    await dispatch('regional/setRegionalData', data.regional_data);
    await dispatch('media/applyEditData', data);
    commit('SET_LISTING_NAME', data.title_suffix);
    commit('SET_LISTING_DESC', data.description);
    commit('SET_LAT', data.lat);
    commit('SET_LNG', data.lng);
    if (data.lat !== null) {
      commit('SET_USE_PIN_LOCATION', true);
    } else {
      commit('SET_USE_PIN_LOCATION', false);
    }
    commit('SET_ADDRESS', data.address);
    commit('SET_PRICE', data.price);
    commit('SET_CATEGORY_NAME', data.category_name);
    commit('SET_UNIT_NUMBER', data.unit_number);
    commit('SET_BLOCK_NUMBER', data.block_number);
    commit('SET_BEDROOM', data.bedroom);
    commit('SET_BATHROOM', data.bathroom);
    commit('SET_BUILDING_SIZE', data.building_size);
    commit('SET_LAND_SIZE', data.land_size);
    commit('SET_TOTAL_FLOOR', data.total_floor);
    commit('SET_FLOOR', data.floor);
    commit('SET_LISTING_NUMBER', data.listing_number);

    if (data.developer_photo) {
      commit('SET_DEVELOPER_PHOTO', {
        src: data.developer_photo.photo_image,
        uuid: data.developer_photo.uuid,
      });
    }

    if (data.property_type_id) {
      const selectedPropertyType = state.propertyTypes.find((item) => {
        return item.id == data.property_type_id;
      });
      commit('SET_SELECTED_PROPERTY_TYPE', selectedPropertyType ? selectedPropertyType : null);
    }
    if (data.certificate_id) {
      const selectedCertificate = state.certificates.find((item) => {
        return item.id == data.certificate_id;
      });
      commit('SET_SELECTED_CERTIFICATE', selectedCertificate ? selectedCertificate : null);
    }
    if (data.furnishing_status_id) {
      const selectedFurnishedStatus = state.furnishedStatuses.find((item) => {
        return item.id == data.furnishing_status_id;
      });
      commit(
        'SET_SELECTED_FURNISHED_STATUS',
        selectedFurnishedStatus ? selectedFurnishedStatus : null,
      );
    }

    for (let key in data.selected_facilities) {
      commit('TOGGLE_SELECTED_FACILITY', key);
      if (data.selected_facilities[key] != null) {
        commit('SET_FACILITY_AMOUNT', {
          facilityId: key,
          amount: parseInt(data.selected_facilities[key]),
        });
      }
    }
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/data_entry_listings/add', {
          params: {
            url: rootState.route.path.substring(1),
            agent_uuid: state.agentUuid,
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
      } else {
        console.log('uuid', state.uuid);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/data_entry_listings/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
            agent_uuid: state.agentUuid,
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async restoreInitialState({ commit, dispatch }) {
    await dispatch('regional/restoreInitialState');
    await dispatch('media/restoreInitialState');
    commit('SET_UUID', null);
    commit('SET_LISTING_NAME', null);
    commit('SET_LISTING_DESC', null);
    commit('SET_LAT', null);
    commit('SET_LNG', null);
    commit('SET_USE_PIN_LOCATION', false);
    commit('SET_ADDRESS', null);
    commit('SET_PRICE', null);
    commit('SET_SELECTED_PROPERTY_TYPE', null);
    commit('SET_SELECTED_CERTIFICATE', null);
    commit('SET_SELECTED_FURNISHED_STATUS', null);
    commit('SET_PROPERTY_TYPES', []);
    commit('SET_CERTIFICATES', []);
    commit('SET_FURNISHED_STATUSES', []);
    commit('SET_CATEGORY_NAME', null);
    commit('SET_UNIT_NUMBER', null);
    commit('SET_BLOCK_NUMBER', null);
    commit('SET_FACILITIES', []);
    commit('SET_BEDROOM', null);
    commit('SET_BATHROOM', null);
    commit('SET_BUILDING_SIZE', null);
    commit('SET_LAND_SIZE', null);
    commit('SET_TOTAL_FLOOR', null);
    commit('SET_FLOOR', null);
    commit('SET_FIELD_RULES', []);
    commit('SET_AGENT_UUID', null);
  },
};
